<planning-poker-landing-header></planning-poker-landing-header>
<div class="content">
    <router-outlet></router-outlet>
</div>
<div class="footer">
    <div class="links">
        <a routerLink="/terms">Terms</a>
        <a routerLink="/privacy">Privacy</a>
    </div>
</div>
<zoom-app-banner bannerLocation="banner_landing"></zoom-app-banner>